.container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  align-items: center;
  justify-content: center;

  &.visible {
    display: flex;
  }
}

.modal {
  max-width: 90%;
  width: 600px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #fff;

  position: relative;
}

:global {
  .is__dark {
    :local {
      .modal {
        background: #4b4b4b !important;
      }
      .closeButton {
        background: #777777 !important;
        border: none !important;
      }
    }
  }
}

.small {
  width: 400px;
}

.header {
  height: 24px;
  /*border-bottom: 1px solid #eaeaf1;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin-top:24px;
}

.body {
  padding: 24px 24px;
  max-height:800px;
  overflow-y:auto;
}

.title {
  font-weight: 700;
  font-size: 20px;
  color: #121223;
}

.closeButton {
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: white;
  border: solid 1px #e2eef1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  right: -15px;
  font-size: 20px;
  line-height: 1px;
}

.footer {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.submitButton {
  min-width: 168px;
  height: 48px;
  box-sizing: border-box;
  padding: 0 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  user-select: none;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.submitButton {
  background-color: #007bff;
  color: #fff;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  opacity: 0.7;
}

@media only screen and (max-width: 600px) {
  .modal {
    padding: 0;
  }

  .title {
    font-size: 20px;
  }

  .footer {
    flex-direction: column;
  }

  .listButton {
    width: calc(100% - 48px);
  }

  .listButton {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
