.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #007bff !important;
}

.css-gr2aqs {
  background-color: #007bff !important;
}

body {
  margin: 0 !important;
  overflow: auto;
  padding-bottom: 100px;
}
.slick-slide {
  outline: none;
  width: unset !important;
  /* width: 360px !important; */
}

body * {
  /* font-family: 'proxima-nova' !important; */
}

.cursor-pointer {
  cursor: pointer;
}

.no-overflow {
  overflow: hidden;
}

.footer__logo img {
  width: 50px;
  height: 50px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.form-container-page {
  background-color: white;
  margin-top: 40px;
  border-radius: 8px;
  margin-bottom: 40px;
}

.MuiPaper-root {
  background: transparent !important;
}

.MuiPopover-paper {
  background: white !important;
}

.is__dark .MuiPopover-paper {
  background: #4d4d4d !important;
  color: white;
}

.is__dark .MuiStepIcon-text {
  color: #4d4d4d !important;
  fill: #4d4d4d !important;
  font-weight: bold;
}

.is__dark .dropdown-menu {
  background: #4d4d4d !important;
}

.is__dark .dropdown-item:hover {
  color: #4d4d4d;
}
.is__dark .dropdown-item:hover * {
  color: #4d4d4d;
}

.is__dark .recharts-default-tooltip {
  background: #4d4d4d !important;
}

.rdt {
  flex: 1;
}

.MuiSwitch-input {
  width: 60px !important;
  height: 30px !important;
  visibility: visible !important;
  left: 0 !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.player-wrapper .item_vdo {
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.player-wrapper.audio {
  padding-top: 0;
  height: 50px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.audio-wrapper {
  position: relative;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.react-player iframe {
  /*border-radius: 8px;*/
}

.react-player video {
  /*border-radius: 8px;*/
}

.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #00a59a !important;
}

.card_body .bg_light {
  background: #f7f7f7;
}
.is__dark .card_body .bg_light {
  background: #4d4d4d !important;
}

.is__dark .card_body .card_head_text {
  border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.is__dark .bg_input {
  background: #4d4d4d !important;
}

.modal {
  padding-left: 15px;
}

.is__dark .ri-checkbox-blank-circle-fill.color_brand:before {
  color: #00a59a;
}

.is__dark .react-loading-skeleton {
  background-color: #666 !important;
  background-image: linear-gradient(90deg, #666, #888, #666) !important;
}

.overflow-hidden {
  overflow: visible !important;
}

.scroll-to-top {
  cursor: pointer;
  position: fixed;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  background: #00a59a;
  color: white;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.is__dark .text-danger .MuiSvgIcon-root {
  fill: #dc3545 !important;
}

.aboutme p {
  font-size: 16px;
}

/*Collection List Page*/
.is__dark .collectionList .stat-card {
  background: #4d4d4d !important;
}

.is__dark .collectionList .stat-card .color_brand {
  color: #00a59a;
}

.is__dark .collectionList .bg_hard_light {
  background: #4d4d4d !important;
}

.collectionList .detail .stat-card {
  border-radius: 8px !important;
}

.collectionList .txt {
  font-size: 16px;
}

.collectionList .owner {
  font-size: 16px;
}

.is__dark .header__1 .header__search.in_mobile input {
  background: #555 !important;
}

.faucetP {
  line-height: 20px;
  font-weight: 700;
}
.claimFreeWanBtn {
  background-color: #00a59a;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  color: white;
  padding: 10px;
  transition: transform 0.3s;
}
.claimFreeWanBtn:hover {
  transform: scale(1.05);
  color: white;
}

.faucetBalance {
  border-radius: 8px;
  display: flex;
  width: 100%;
  background-color: #f7f7f7;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  justify-content: space-between;
}
.is__dark .faucetBalance {
  background-color: #4d4d4d !important;
}
.is__dark .faucetBalanceTitle {
  border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.is__dark .color_brand {
  color: #00a59a !important;
}
.faucetBalanceTitle {
  min-width: fit-content;
  line-height: 20px;
  font-weight: bold;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: 1px solid #eeeeee;
  padding-left: 15px;
  padding-right: 15px;
  color: #7e7e7e;
}

.faucentBalanceAmount {
  min-width: fit-content;
  line-height: 16px;
  font-weight: bold;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  color: #7e7e7e;
}
.faucentBalanceAmountIcon {
  display: flex;
  align-items: center;
}
.wanImage {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.faucentBalanceAmount span {
  font-size: 12px;
  font-weight: normal;
}

.card__item .card_title a.text-danger svg {
  color: #dc3545;
}

.card__item .card_title a.text-danger path {
  color: #dc3545;
}

.is__dark .alert-danger {
  color: #842029 !important;
}

.is__dark .alert-danger b {
  color: #842029 !important;
}

.is__dark .alert-danger path {
  color: #842029 !important;
}

@media screen and (max-width: 768px) {
  .homepage-center-btn {
    margin-left: 10px;
    justify-content: center;
  }
}
input:read-only,
input:read-only:focus {
  cursor: not-allowed;
}
.react-dropdown-select-dropdown-handle {
  display: flex;
}

/* Elixir */
.elixir
{
  display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.elixir .bottle {

  z-index: 2;
  width: fit-content !important;
    height: fit-content  !important;
}

.elixir .fill {
  width: 128px !important;
  height: 128px !important;
  position: absolute;
  /* 100 drop = 75% so ... 50 drops = 50*75/100 = 37.5%*/
  background: linear-gradient(0deg, red 37.5%, rgba(255, 255, 255, 0) 0%);
  object-fit: scale-down;
  mask-size: scale-down;
  mask-repeat: no-repeat;
  mask-position: center center;
  z-index: 1;

  transform: scale(1.03);
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.elixir:hover .fill
{
  transform: scale(1.1);
}

.item_details .elixir:hover .fill
{
  transform: scale(1.03);
}


.item_details .item_elixir {
  display:flex;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  min-height:300px;
  /*border: 1px solid #e2eef1;*/
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-top:100%;
}

.item_details .item_elixir .elixir
{
  position:absolute;
  top:0;
}


.elixir .bottle_bg {
  width: 128px !important;
  height: 128px !important;
  position: absolute;
}

.color_black.active
{
  color: #00a59a !important;
  font-weight: bold !important;
}

#nprogress .bar
{
  height:5px !important;
  background: #00a59a !important;
}