.dropdown-menu {
    border: 1px solid $color_stroke !important;
    box-shadow: $shadow_2;
    border-radius: 8px;
}
.share {
    position: relative;
    .icon {
        cursor: pointer;
        background: $color_white;

        height: 3rem;
        width: 3rem;
        box-shadow: $shadow_2;
        border: solid 1px $color_stroke;
        border-radius: $border_radius * 100;
        transition: all 0.25s $transition_function;
        @include center;
        &:hover {
            box-shadow: none;
            background: $color_stroke;
        }
        i {
            color: $color_black;
            font-size: 18px;
        }
    }
    .share_box,
    .dropdown__popup {
        z-index: 99;
        @include card_item;
        transition: all 0.25s $transition_function;
        a {
            color: $color_black;
            display: block;

            &:hover {
                color: $color_brand;
                transform: scale(1.3);
            }
        }
        i {
            font-size: 20px;
        }
    }
    .dropdown__popup {
        position: absolute;
        top: 60px;
        left: 50%;
        visibility: hidden;
        opacity: 0;
        transform: translateX(-50%) translateY(-1rem);
        &.visible {
            visibility: visible;
            opacity: 1;
            transform: translateX(-50%) translateY(0);
        }
    }
}
.more {
    position: relative;
    z-index: 99;
    .icon {
        cursor: pointer;
        height: 3rem;
        width: 3rem;
        box-shadow: $shadow_2;
        border: solid 1px $color_stroke;
        border-radius: $border_radius * 100;
        transition: all 0.25s $transition_function;
        background: $color_white;
        @include center;
        &:hover {
            box-shadow: none;
            background: $color_stroke;
        }
        i {
            color: $color_black;
            font-size: 18px;
        }
    }
    .more_box,
    .dropdown__popup {
        z-index: 99;
        @include card_item;
        padding: 10px 20px;
        transition: all 0.25s $transition_function;
        a {
            color: $color_black;
            display: block;
            align-items: center;

            &:hover {
                color: $color_brand;
            }
        }
        i {
            font-size: 20px;
            color: $color_red;
        }
    }
    .dropdown__popup {
        position: absolute;
        top: 60px;
        right: 0;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        transform: translateY(-1rem);
        &.visible {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }
    }
}
.result {
    .ri-search-line {
        position: relative;
        top: 2px;
    }
}

.header__result {
    .ri-search-line {
        position: relative;
        top: 1px;
    }
}
